@import 'styles/variables.scss';

.filterContainer {
  background-color: $white;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;

  .divider {
    width: 100%;
    height: 1px;
    background-color: $divider-color;
    margin: 25px 0;
  }

  .status {
    .statusOptions {
      display: grid;
      flex-direction: row;
      grid-template-columns: auto auto;
      color: $black-70;
    }
  }

  .rowArea {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: 25px;
  }

  .applyContainer {
    background-color: $white;
    padding: 20px 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    border-top: 1px solid $divider-color;
  }
}
