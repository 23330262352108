@import 'styles/variables.scss';

.listItemContainer {
  padding: 16px;
  background-color: $white;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
