@import 'styles/variables.scss';

.inventoryDetailContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;

  .cardContainer {
    padding: 24px 16px;
    display: flex;
    border-bottom: 1px solid $divider-color;

    .informationContainer {
      padding-left: 16px;
    }
  }

  .changeQtyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
    margin-top: 24px;
  }

  .footerContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-bottom: 12px;
  }
}
