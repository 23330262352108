@import 'styles/mixins';

body {
  overflow-x: hidden;
  padding: 0;
  margin: auto;
  position: relative;
  height: 100vh;
  width: 100%;
  overscroll-behavior-x: none;
  font-weight: 400;

  @include tablet {
    background-color: $mobile-background-color;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
}
.MuiDataGrid-virtualScroller {
  overflow-y: scroll !important;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
