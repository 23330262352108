.ordersContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.pagination {
  margin-top: 20px;
  padding: 12px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
