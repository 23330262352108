.tabsContainer {
  position: fixed;
  width: 100%;
  display: flex;
  background-color: white;
  z-index: 2;

  .tabItemContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 12px 0;

    &.active {
      border-bottom: 2px solid #000;
    }
  }
}
