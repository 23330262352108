@import 'styles/variables.scss';

.mobileContainer {
  left: 0;
  right: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .spacerBottom {
    height: 80px;
  }
}
