.description {
  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 25px !important;
    -webkit-margin-start: 5px;
  }

  p {
    padding: 0 0 12px 0 !important;
  }
}