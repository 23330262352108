@import 'styles/variables.scss';

.inventoryContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 15px;

  .editable {
    background-color: $mobile-background-color;
  }
}