@import 'styles/variables.scss';

.searchBarContainer {
  background-color: $white;
  height: 60px;
  padding: 0 16px;
  position: fixed;
  top: 60px;
  z-index: 999;
  width: calc(100% - 32px);

  .searchBarInnerContainer {
    height: 44px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    padding: 0 12px;
    gap: 12px;
  }
}
