@import 'styles/variables.scss';

.allOrdersContainer {
  padding: 80px 0 76px 0;
  background-color: red !important;
}

.ordersContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pagination {
  max-width: 100%;
  margin-top: 20px;
  padding: 12px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
