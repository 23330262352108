@import 'styles/variables.scss';

.swiperItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid $divider-color;
  padding-bottom: 16px;

  .image {
    margin: 0 16px 0 0;
    background-color: #f0f0f0;
  }

  .itemDetail {
    width: 100%;
  }

  .status {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
