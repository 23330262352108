@import 'styles/variables.scss';

.indicatorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 16px;
  gap: 4px;
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: $black;
  opacity: 0.2;
  transition: width 0.3s, background-color 0.3s;

  &.active {
    width: 20px;
    height: 6px;
    border-radius: 3px;
    background-color: $black;
    opacity: 1;
    transition: width 0.3s, background-color 0.3s;
  }
}
