.customPagination {
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1);
  min-height: 52px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
}
