@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.topTabContainer {
  width: auto;
  display: flex;
  padding: 16px 0;
  gap: 8px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include tablet {
    padding: 12px 8px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .tabItem {
    display: flex;
    padding: 6px 12px;
    max-height: 32px;
    background-color: $white;
    border-radius: 16px;
    font-weight: 400;
    border: 1px solid $divider-color;
    cursor: pointer;

    &.active {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
}
