@import 'styles/variables.scss';

.deliveriesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 35px;

  .tabContainer {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $divider-color;

    .tabItemContainer {
      width: 280px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        border-bottom: 3px solid $black;
      }
    }
  }
}
