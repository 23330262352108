.inventoryContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
}

.pagination {
  margin-top: 20px;
  padding: 12px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
