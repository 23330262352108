@import 'styles/variables.scss';

.mobileHeaderContainer {
  position: fixed;
  display: flex;
  height: 60px;
  padding: 0 16px;
  align-items: center;
  overflow: hidden;
  background-color: $white !important;
  border-bottom: 1px solid $divider-color;
  z-index: 999;

  &.borderless {
    border-bottom: none !important;
  }

  .midSide {
    flex: 1;
    text-align: center;
  }

  .rightSide {
    display: flex;
    justify-content: flex-end;
  }
}

.headerArea {
  margin-top: 60px;
  z-index: 99;
}

.filterBadge {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: $crayola-blue;
  border-radius: 4px;
  right: 0;
}
