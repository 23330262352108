@import 'styles/variables.scss';

.allOrdersContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .logo {
    width: 162px;
    margin-left: 15px;
  }

  .nav {
    height: 100vh;
    background-color: $gunmetal;
    width: 240px;
    padding: 23px 0;

    ul {
      margin-top: 45px;

      li {
        display: flex;
        padding: 14px 0 14px 20px;
        cursor: pointer;
        color: $white;
        gap: 16px;

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.06);
        }

        &.active {
          background-color: rgba($color: #fff, $alpha: 0.06);
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }
}
