.orderItemContainer {
  display: flex;
  gap: 24px;
  padding: 24px 50px 23px 24px;
  background-color: white;

  .imageContainer {
    cursor: pointer;
  }

  .itemDetailContainer {
    width: 100%;

    .itemDetailInnerContainer {
      display: flex;
      gap: 64px;

      .detailButton {
        margin-top: 8px;
        color: #1976d2;
        text-transform: none;
        min-width: unset;
        padding: 0;
        font-weight: 400;
      }
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: auto;
  }

  .customOptionsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
  }
}