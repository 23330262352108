.accountContainer {
  width: 548px;
  display: flex;
  flex-direction: column;

  textarea {
    height: 95px !important;
    padding-top: 12px;
  }
}
