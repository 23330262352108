@import 'styles/variables.scss';

.cardContainer {
  margin: 20px 0;
  padding: 16px;
  background-color: $white;

  .productDetailContainer {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .detailButton {
      margin-top: 8px;
      color: #1976d2;
      text-transform: none;
      min-width: unset;
      padding: 0;
      font-weight: 400;
    }
  }

  .statusRow {
    display: flex;
    padding: 7px 0;
  }

  .customOptionsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
