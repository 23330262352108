$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-big-tablet: 1280px;

// colors
$black: #000;
$black-90: rgba(0, 0, 0, 0.9);
$black-80: rgba(0, 0, 0, 0.8);
$black-70: rgba(0, 0, 0, 0.7);
$black-60: rgba(0, 0, 0, 0.6);
$black-50: rgba(0, 0, 0, 0.5);
$gunmetal: #212d31;
$white: #ffffff;
$disabled-button-color: rgba(0, 0, 0, 0.26);
$green: #28a745;
$red: #dc3545;
$purple: #ba00ff;
$orange: #f58808;
$amber: #ffc107;
$navy-blue: #2867a7;
$br-navy-blue: #1976d1;
$crayola-blue: #3f8bc7;
$aol-green: #2e7d32;
$green: #28a745;
$divider-color: #dee2da;
$mobile-background-color: #f3f4f4;
$snopia: #bd512f;
$camel: #a58a63;

// font sizes
$lg-text: 1.125rem;
$md-text: 1rem;
$sm-text: 0.875rem;
$xs-text: 0.75rem;

// font weight
$normal-weight: 500;

:export {
  black: $black;
  black90: $black-90;
  black80: $black-80;
  black70: $black-70;
  black60: $black-60;
  black50: $black-50;
  gunmetal: $gunmetal;
  white: $white;
  disabledButtonColor: $disabled-button-color;
  green: $green;
  red: $red;
  purple: $purple;
  orange: $orange;
  amber: $amber;
  navyBlue: $navy-blue;
  brNavyBlue: $br-navy-blue;
  crayolaBlue: $crayola-blue;
  aolGreen: $aol-green;
  dividerColor: $divider-color;
  mobileBackgroundColor: $mobile-background-color;
  snopia: $snopia;
  camel: $camel;
}
