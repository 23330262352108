@import 'styles/variables.scss';

.blockContainer {
  background-color: $white;
  padding: 24px 16px;
  margin-top: 20px;

  textarea {
    height: 95px !important;
    padding-top: 12px;
  }
}
