@import 'styles/variables.scss';

.pageListContainer {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 27px 16px 27px 19px;
  gap: 26px;
  cursor: pointer;
  border-bottom: 1px solid $divider-color;

  .textArea {
    display: flex;
    flex: 1;
  }
}
