@import 'styles/mixins.scss';

.alertContainer {
  position: absolute;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  width: 60%;
  margin: auto;

  @include tablet {
    width: 100%;
  }
}
