@import 'styles/variables';

@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin minTablet {
  @media (min-width: calc($breakpoint-mobile)) {
    @content;
  }
}

@mixin bigTablet {
  @media (max-width: $breakpoint-big-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}
