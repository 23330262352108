@import 'styles/variables.scss';

.giftMessageContainer {
  padding: 20px 16px;
  background-color: #fff;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 19px;
  word-break: break-all;
}

.shipContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 16px;
}
