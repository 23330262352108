@import 'styles/variables.scss';

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $white;

  .logo {
    max-height: 110px;
    max-width: 100px;
    align-self: center;
    margin-bottom: 30px;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
