@import 'styles/variables.scss';

.webWrapper {
  height: calc(100% - 64px);
  padding: 84px 0 0 270px;
  max-width: calc(100% - 240px);

  .logo {
    width: 162px;
    margin-left: 15px;
  }

  .nav {
    height: 100vh;
    background-color: $gunmetal;
    width: 240px;
    padding: 23px 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;

    ul {
      margin-top: 45px;

      li {
        display: flex;
        padding: 14px 0 14px 20px;
        cursor: pointer;
        color: $white;
        gap: 16px;

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.06);
        }

        &.active {
          background-color: rgba($color: #fff, $alpha: 0.06);
        }
      }
    }

    .sideBarFooterContainer {
      padding-top: 32px;
      height: calc(100% - 390px);
      position: relative;

      .sideBarFooter {
        display: flex;
        position: absolute;
        left: 20px;
        bottom: 0;
        color: $white;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
    }
  }

  .page {
    height: calc(100vh - 84px);
    width: 100%;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    padding-bottom: 24px;
    padding-right: 50px;
  }

  .header {
    width: calc(100% - 240px);
    height: 64px;
    padding: 12px 30px;
    background-color: $br-navy-blue;
    display: flex;
    align-items: center;
    color: $white;
    position: fixed;
    top: 0;
    left: 240px;
    justify-content: space-between;
    z-index: 99;
  }
}
