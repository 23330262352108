@import 'styles/variables.scss';

.orderDetailContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .productsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
  }

  .deliveryAndGiftMessageContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;

    .giftMessageContainer {
      display: flex;
      flex-direction: column;
      width: 50%;
      background-color: $white;
      padding: 24px;
      word-break: break-all;
    }
  }
}
