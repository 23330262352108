.filterContainer {
  .rowContainer {
    display: flex;
    gap: 12px;
    padding: 16px 0;
    align-items: center;
  }

  .buttonContainer {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }
}
